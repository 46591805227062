import React, { FunctionComponent, useState } from 'react';
import tw from 'twin.macro';

import { BusinessDetailTabProps } from '../@types/BusinessDetailTabProps';
import { useGetBusinessCatalogs } from '@services/business.service';
import { CircularProgress } from '@material-ui/core';
import BusinessDetailEmptyListComponent from './business-detail-empty-list';
import CatalogComponent from '@components/menu-card/CatalogComponent';

/*
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
  }),
);
*/

const TabContainer = tw.div`w-full h-full`;

export const BusinessDetailComponentCatalogs = ( {business}: BusinessDetailTabProps) => {
   const { data: catalog, error, isFetching } = useGetBusinessCatalogs(business.slug);

  const showList = () => {

    console.log('catalog ', catalog);
      return (
        <>
        {catalog && catalog.data &&
          <div className="d-flex flex-column mt-2 w-100">
            {/*
            <BusinessDetailComponentCatalogItem catalog={catalog} key={`catalog-${catalog.token}`}/>
            */}
            <CatalogComponent menu={catalog.data}></CatalogComponent>
          </div>
        }

        {(!catalog || !catalog.data) &&
        <BusinessDetailEmptyListComponent text="En estos momentos no existen cartas activas en este establecimiento"/>
        }
        </>
      );
  }
  // const catalogs: IMenu[] = data?.content;
  return (
    <>
    {!error && catalog === undefined && 
    <CircularProgress color="primary" />
    }

    {catalog && 
        showList()
    }

    { error && 
    <BusinessDetailEmptyListComponent text="En estos momentos no contamos se encuentra una con carta digital en este establecimiento"/>
    }
    </>
  );
}



export default BusinessDetailComponentCatalogs;