import React, { FunctionComponent, useState } from 'react';
import tw from 'twin.macro';

import { BusinessDetailTabProps } from '../@types/BusinessDetailTabProps';
import { useGetBusinessMenus } from '@services/business.service';
import { CircularProgress } from '@material-ui/core';
import BusinessDetailComponentCatalogItem from './business-detail-catalog.item';
import BusinessDetailMenuOfDayComponent from './business-detail-menuofday';
import { IMenu } from '@models/menu.model';
import BusinessDetailEmptyListComponent from './business-detail-empty-list';
import { IAPIResponse, IPagination } from '@models/api-response.model';
import { QueryResult } from 'react-query';
import { IMenuOfDay } from '@models/menuofday.model';

const MenusContainer= tw.div`sm:pt-0 md:pt-10 z-0 grid grid-cols-2 sm:grid-cols-4 gap-4 w-full justify-start`;

export const BusinessDetailComponentMenus = ( {business}: BusinessDetailTabProps) => {
  const { status, data, error, isFetching }: QueryResult<IAPIResponse<IPagination<IMenuOfDay>>, Error> = useGetBusinessMenus(business.slug);


  const showList = () => {

    // console.log('showList menusofday page ', data);
      return (
        <>
        {data?.data?.content?.length > 0 && 
        <MenusContainer>
          {data?.data?.content.map((menu, index) => (
              <BusinessDetailMenuOfDayComponent menu={menu} business={business} key={`menu-${menu.token}`}/>
          ))}
        </MenusContainer>
        }

        {data?.data?.content?.length === 0 &&
        <BusinessDetailEmptyListComponent text="En estos momentos no existen menús del día en este establecimiento"/>
        }
        </>
      );
  }
  // const catalogs: IMenu[] = data?.content;
  return (

    <>
    {data === undefined && 
    <CircularProgress color="primary" />
    }

    {data && 
        showList()
    }
    </>
  );
}



export default BusinessDetailComponentMenus;