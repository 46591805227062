import styled from 'styled-components';

const TabContainer = styled.div`
  // width: 95vw;
  min-height: 40vh;
  display: initial;



  @media (max-width: 576px) {
    min-height: 50vh;
    
  }  
`

export default TabContainer;