import React, { FunctionComponent, useState } from 'react';
import tw from 'twin.macro';
import CatalogDefaultImage from '@assets/images/app/business/board.svg';

import { IMenuOfDay, IBusiness } from '@models';
import ModalComponent from '@components/Modal/modal-component';

import AppetizersIcon from '@assets/images/app/menuofday/salad.png';
import MainCourseIcon from '@assets/images/app/menuofday/dish.png';
import DessertIcon from '@assets/images/app/menuofday/cake.png';

/*
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
  }),
);
*/

const CatalogImage = tw.img`h-12 w-12 object-cover`;
const CatalogName = tw.span`text-xl text-primarydark `;
const CatalogDefaultImageStyled = tw.img`w-16`;
const CatalogProductsNumber = tw.span`ml-2 text-base font-bold text-gray-800 capitalize`;
const MenuIconImage = tw.img`w-10 h-auto`;
const MenuBusinessName = tw.div`font-bold text-primarydark text-3xl pb-3 pt-1`;
const MenuPrice = tw.div`font-bold text-gray-600 text-4xl pb-3 pt-1`;

interface BusinessDetailMenuOfDayProp {
    menu: IMenuOfDay,
    business: IBusiness
}


export const BusinessDetailMenuOfDayComponent: FunctionComponent<BusinessDetailMenuOfDayProp> = ( {business, menu}) => {

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  }

  return (
    <div className="d-flex flex-row my-3 p-2 rounded-lg border-t border-b sm:border-l sm:border-r shadow-sm cursor-pointer" >
      <div className="d-flex w-100 items-center justify-between bg-white px-8 py-6">
          <div className="d-flex w-100" onClick={() => openModal()}>
              {menu?.image && 
              <CatalogImage
                  src={menu.image?.url}
                  alt={menu.name} />
              }

              {!menu?.image && 
              <CatalogDefaultImageStyled 
              src={CatalogDefaultImage}/>
              }
              <div className="d-flex flex-column ml-4 justify-content-center">
                  <CatalogName className="font-weight-bold">{menu.name}</CatalogName>
                  <CatalogProductsNumber>{menu.price} €</CatalogProductsNumber>
              </div>
          </div>
      </div>

      <ModalComponent  
            size="lg"
            centered
            title={menu.name}
            onHide={() => setShowModal(false)}
            handleClose={() => setShowModal(false)}
            show={showModal}>
              <div className="d-flex ">
                <MenuBusinessName className="flex-grow-1">{business.name}</MenuBusinessName>
                <MenuPrice className="align-self-end">{menu.price} €</MenuPrice>
              </div>
              <h3 className="d-flex align-items-center text-gray-900 font-bold text-xl py-2 rounded bg-light px-2">
                <MenuIconImage src={AppetizersIcon} className="w-10 h-10 pr-2"/>
                Primeros</h3>
                <div className="text-grat-600 text-base pl-3 pr-2" dangerouslySetInnerHTML={{
                        __html: menu.appetizers}}></div>

            <h3 className="d-flex align-items-center text-gray-900 font-bold text-xl py-2 bg-light px-2">
                <MenuIconImage src={MainCourseIcon} className="w-10 h-10 pr-2"/>
                Segundos</h3>
              <div className="text-grat-600 text-base pl-3 pr-2" dangerouslySetInnerHTML={{
                        __html: menu.mainCourse}}></div>                        

            <h3 className="d-flex align-items-center text-gray-900 font-bold text-xl py-2 bg-light px-2">
              <MenuIconImage src={DessertIcon} className="pr-2"/>
              Postre</h3>
              <div className="text-grat-600 text-base pl-3 pr-2" dangerouslySetInnerHTML={{
                        __html: menu.dessert}}></div>

              <div className="text-grat-600 text-base rounded bg-light px-2 py-3 mt-2 mb-4" dangerouslySetInnerHTML={{
                        __html: menu.extra}}></div>
      </ModalComponent>


    </div>
  );
}


export default BusinessDetailMenuOfDayComponent;