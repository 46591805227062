import React, { useState } from 'react';
import tw from 'twin.macro';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { Container, ContentWithPadding } from '@components/Layout';
import { SectionHeading } from '@components/misc/Headings.js';
import { PrimaryButton as PrimaryButtonBase } from '@components/misc/Buttons.js';
import Img from 'gatsby-image';

import ImageProduct from '@assets/images/app/business/product.png';
import ImageDefaultProduct from '@assets/images/app/business/dish.svg';
import StarIconSrc from '@assets/images/app/star.png';
import  SvgDecoratorBlob1 from '@assets/images/app/decorators/svg-decorator-blob-9.svg';
import { IMenu } from '@models';

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row items-start`;
const Header = SectionHeading;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 py-2 px-2 rounded leading-none mt-4 md:mt-0 xl:mt-0`;


const TabControl = styled.div`
  ${tw`cursor-pointer px-4 py-3 mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-2 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12 w-full`;
const CardContainer = tw.div`mt-2 md:mt-10 w-1/2 md:w-1/4 lg:w-1/5 sm:pr-2 md:pr-6 lg:pr-12`;
const Card = tw(motion.div)`bg-gray-200 ml-2 rounded-b block max-w-xs  sm:max-w-none sm:mx-0 h-full hover:no-underline flex flex-col`;

const CardImageContainer = styled.div`
  ${props => css`background-image: url('${props.imageSrc}');`}
  ${tw`h-20 md:h-40 xl:h-40 bg-center bg-cover bg-no-repeat relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900 flex-grow flex flex-col`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardPrices = tw.ul`m-0 p-0 pt-2 flex-grow flex items-end flex-col`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600 hover:no-underline leading-4`;
const CardAllergens = tw.div`flex flex-wrap`;
const CardAllergen = tw.img`w-10 md:w-12 px-2 py-1`;
const CardPrice = tw.span`mt-4 text-sm md:text-base font-bold`;






const ListContent = tw(motion.div)`flex flex-wrap mt-12 justify-center`;
const ListContainer = tw.div`grid grid-cols-9 items-start py-2`;
const ListColImage = tw.div`col-span-2 sm:col-span-1 xl:col-span-1 pr-1`;
const ListImage = tw.img`h-16 w-16 md:h-24 md:w-24 rounded  mx-auto`;
const ListColContent = tw.div`col-span-6 sm:col-span-4 xl:col-span-4`;
const ListColPrice = tw.div`col-span-1 sm:col-span-1 xl:col-span-1 italic `;


interface MenuProps {
  menu:IMenu;
}

const CatalogComponent = ({menu}:MenuProps ) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as te key and value of te key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for 'Starters'.
   */
  const sections = menu.sections;

  const [activeTab, setActiveTab] = useState(sections[0].name);
  const [grid, setGrid] = useState(true);

  return (
    <Container className="w-100">
        {sections &&
        <>
        <HeaderRow>
          <TabsControl>
            {sections.map((section, index) => (
              <TabControl key={index} active={activeTab === section.name} onClick={() => setActiveTab(section.name)}>
                {section.name}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {grid && sections.map((section, index) => (
          <TabContent
          key={index}
            variants={{
              current: {
                opacity: 1,
                scale:1,
                display: 'flex',
              },
              hidden: {
                opacity: 0,
                scale:0.8,
                display: 'none',
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === section.name ? "current" : "hidden"}
            animate={activeTab === section.name ? "current" : "hidden"}
          >
            {section.products?.map((product, index) => (
              <CardContainer key={index}>
                <Card className="group" initial="rest" whileHover="hover" animate="rest">
                  <CardImageContainer imageSrc={product?.image?.url?product.image.url:ImageDefaultProduct}>
                    {
                    /*
                    <CardRatingContainer>
                      <CardRating>
                        <img src={StarIconSrc}/>
                        5.0
                      </CardRating>
                      <CardReview>(34)</CardReview> 
                    </CardRatingContainer>
                    */}
                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: 'auto'
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      {/*<CardButton>Pedir</CardButton>*/}
                    </CardHoverOverlay>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{product.name}</CardTitle>
                    <CardContent>{product.description}</CardContent>
                    {product.allergens &&
                    <CardAllergens>
                      {product.allergens.map(allergen => (
                        <CardAllergen src={`/images/app/allergens/${allergen.iconName}.png`} alt={allergen.name}/>
                      ))}

                    </CardAllergens>
                    }
                      <CardPrices>
                      {product.prices.map(price => {
                        return price.price && (
                        <li key={`price-${price.id}`}><CardPrice>{price.name} {price.price} €</CardPrice></li>
                        )}
                      )}
                      </CardPrices>
                  </CardText>
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
        </>
      }
    </Container>
  );
};

export default CatalogComponent;