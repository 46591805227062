import React from 'react';
import tw from 'twin.macro';
import { Modal as ReactModal }  from 'react-bootstrap';
import ModalContainer from './modal.style';

interface ModalProps {
    handleClose:any,
    handleShow: any,
    title: string,
    body:any,
    show: boolean
}


const ModalTitle = tw.div`font-bold text-highlight font-serif text-2xl`;

const ModalComponent = (props) => {

    //const [show, setShow] = useState(false);

    //const handleClose = () => setShow(false);
    //const handleShow = () => setShow(true)

  return (
    <ModalContainer>
        <ReactModal onHide={props.handleClose} {...props} centered className="modal-dialog modal-xl modal-dialog-scrollable">
        <ReactModal.Header closeButton>
            <ReactModal.Title className="text-uppercase"><ModalTitle>{props.title}</ModalTitle></ReactModal.Title>
        </ReactModal.Header>

        <ReactModal.Body>
            {props.children}
        </ReactModal.Body>
        <ReactModal.Footer>
            <button type="button" className="btn btn-outline-dark" data-dismiss="modal" onClick={props.handleClose}>Cerrar</button>
        </ReactModal.Footer>
            
        
        </ReactModal>
    </ModalContainer>
  );
}

export default ModalComponent;