import React from 'react';
import PropTypes, { number } from 'prop-types';
import TabContainer from "./tabpanel.style";

interface TabPanelProps  {
    children?: ReactNode,
    index: number,
    tab: number
}

const TabPanel = (props: TabPanelProps) => {
  const { children, tab, index, ...other } = props;

  return (
    <TabContainer
      className = "pt-1 mx-0 mx-sm-4"
      role="tabpanel"
      hidden={tab !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {tab === index && (
        <div className="w-100 flex-column d-flex flex-grow-1">
          {children}
        </div>
      )}
    </TabContainer>
  );
}

export default TabPanel;