import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF,faTwitter,faInstagram,faYoutube,faLinkedinIn,faTripadvisor } from '@fortawesome/free-brands-svg-icons'
import tw from 'twin.macro';

import { BusinessRRSSLinkProps } from "../@types/BusinessRRSSLinkProps";

const LinkComponent = tw.a`mr-4 text-primary-light-800 p-3 text-center inline-flex items-center justify-center w-12 h-12 mt-2 shadow md:shadow-lg rounded-full bg-white`;

export const BusinessRRSSLink = ( {rrssLink}: BusinessRRSSLinkProps) => {


    const rrssIcon = (): any => {

        if (rrssLink.link.includes('facebook') ) {
            return faFacebookF;
        } else if(rrssLink.link.includes('twitter')) {
            return faTwitter;
        } else if(rrssLink.link.includes('instagram')) {
            return faInstagram;
        } else if(rrssLink.link.includes('youtube')) {
            return faYoutube;            
        } else if(rrssLink.link.includes('linkedin')) {
            return faLinkedinIn;            
        } else if(rrssLink.link.includes('tripadvisor')) {
            return faTripadvisor;                        
        }

        return faShareAlt;
    }

//    console.log('business -- links ' + rrssLink.link, rrssIcon());

    return (
            <LinkComponent target="_blank" href={rrssLink?.link}>
                <FontAwesomeIcon icon={rrssIcon()}/>
            </LinkComponent>
    );
}

export default BusinessRRSSLink;
