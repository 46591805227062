
import React, { useState, useRef, useEffect } from 'react';
import tw from 'twin.macro';
import { Link } from 'gatsby';
import { useData, useLoading } from '@data-provider/react';
import SwipeableViews from 'react-swipeable-views';
import Rating from '@material-ui/lab/Rating';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
// import { makeStyles } from '@material-ui/core/styles';
// import mapboxgl from 'mapbox-gl';
import { Marker } from 'react-leaflet';

import Layout from '@components/Layout';
import Header from '@components/Header';
import SEO from '@components/Seo';
import MiniFooter from '@components/Footer/MiniFooter';
import CookieBanner from '@components/Cookies/CookieBanner';

import { BusinessDetailProps } from './@types/BusinessDetailProps';
import { getBusinessPathVariables } from '@utils';
import { useGetBusiness  } from '@services/business.service';
import { MapServiceProviders } from '@components/map/map.model';
import { promiseToFlyTo } from '@components/map/map.service';
import InfoMap  from '@components/map/InfoMap';
import InfoMarker  from '@components/map/InfoMarker';
import L from 'leaflet';
import TabPanel from '@components/tab/TabPanel';
import ImagePin from '@assets/images/app/business/pin.png';
import ImageEmpty from '@assets/images/app/business/empty.svg';


import { IBusiness } from '@models/business.model';
import BusinessDetailComponentCatalogs from './components/business-detail-catalogs';
import BusinessRRSSLink from './components/business-rrsslink';
import BusinessDetailEmptyListComponent from './components/business-detail-empty-list';
import BusinessDetailComponentMenus from './components/business-detail-menus';
import LayoutContent from '@components/LayoutContent';
import { CircularProgress } from '@material-ui/core';
import { BusinessDetailComponentPromos } from './components/business-detail-promos';

const useStyles = makeStyles({
    tabLabel: {
        '&:focus': {
            outline: 'none !important'
        }

    },
});

// -- tarjeta
// -- https://tailwindcomponents.com/component/product-preview
const SectionContainer= tw.section`p-4 pt-4 mt-16  md:mt-0 md:pt-32 flex flex-wrap flex-row items-stretch w-full justify-center`;
const BusinessCard = tw.div`md:flex shadow-lg  mx-0 md:mx-auto w-full h-40 md:h-64`;
const BusinessImage= tw.img`h-full w-full md:w-1/3  object-cover rounded-lg rounded-r-none pb-2`;
const BusinessContent = tw.div`w-full md:w-2/3 px-4 py-4 bg-white rounded-lg`;
const BusinessName = tw.h2`text-xl text-gray-800 font-medium mr-auto`;
const BusinessDescription = tw.div`text-sm text-gray-700 mt-4`;

// -- tarjeta
// -- https://tailwindcomponents.com/component/stripe-carousel-clone
const BusinessIImageContainer = tw.div`relative w-full md:w-2/5 h-full overflow-hidden rounded-t-md md:rounded-t-none md:rounded-l-lg`;
const BusinessIImage = tw.img`absolute inset-0 w-full h-full object-cover object-center`;
const BusinessIImageOverlay = tw.div`absolute inset-0 w-full h-full bg-primary-900 opacity-50`;
const BusinessIImageTitle = tw.div`pl-2 md:leading-10 text-4xl md:text-5xl font-black absolute inset-0 w-full h-full flex items-center justify-center fill-current text-white`;
const BusinessIContent = tw.div`hidden md:flex flex-col p-4 md:px-12 md:py-6 w-full md:w-2/3 md:w-3/5 md:h-full items-start bg-gray-100 rounded-lg`;
const BusinessIDescription = tw.div`text-sm md:text-base text-gray-900 w-full flex-grow`;

const BusinessAddressContainer = tw.div`flex w-full hover:cursor-pointer hover:text-gray-800`;
const BusinessAddressText = tw.span`text-sm flex-grow`;
const BusinessAddressPin = tw.img`h-5`;

const BusinessInfoRating = tw.div`flex flex-grow text-gray-500 font-serif text-sm justify-start items-start`;
// -- banner
// -- https://tailwindcomponents.com/component/information-presenting-card-3

// -- ratings
// -- https://tailwindcomponents.com/component/user-review-with-ratings
const BusinessVerified = tw.span`text-green-600 text-xs`;
const BusinessRatingContainer = tw.div`flex items-center mt-1`;

// -- tabs
const BusinessTabContainer= tw.div`w-full flex`;

const TabContent=tw.div`w-full flex flex-col flex-grow items-center sm:justify-center`;
const TabContentStart=tw(TabContent)`justify-start  sm:justify-start`;
const ImageTabEmpty=tw.img`w-2/3 sm:w-1/3`;
const EmptyText = tw.span`text-primary-800 text-center font-sans sm:text-xl mx-2 sm:mx-auto mt-2`;

function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      className: `tab-label`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}



const timeToZoom = 2000;
const timeToOpenPopupAfterZoom = 4000;
const timeToUpdatePopupAfterZoom = timeToOpenPopupAfterZoom + 3000;

const BusinessDetail : React.FC<BusinessDetailProps> = props => { 


    const { location } = props;


    const pathVariables:string[] = getBusinessPathVariables(location.pathname);
    const slug: string = pathVariables[0];
    const { status, data, error, isFetching } = useGetBusiness(slug);

    const business:IBusiness = data?.data;

    let isMobile = false;
    if (typeof window !== 'undefined') {
        isMobile = window.innerWidth < 480;
    }

    const [tab, setTab] = useState(isMobile?0:1);

    useEffect(()=> {
        if (!isMobile && business && ! (business.address.latitude)) {
         setTab(2);
        }
    },[business]);

    
    
    const handleTabChange = (event, newValue: number) => {
        setTab(newValue);
    };

    const handleTabChangeIndex = (index: number) => {
        setTab(index);
      };


    // -- map
    const markerRef = useRef();

    let LOCATION = {
        lat: process.env.LATITUDE,
        lng: process.env.LONGITUDE        
        // lat: 40.416775,
        // lng: -3.70379,  
//        lat: business.address?.latitude,
//        lng: business.address?.longitude
      };
    
    const DEFAULT_ZOOM = process.env.DEFAULT_ZOOM;
    const ZOOM = process.env.ZOOM;

    if (status === 'success'){

    }
  /**
   * mapEffect
   * @description Fires a callback once the page renders
   * @example Here this is and example of being used to zoom in and set a popup on load
   */
  async function mapEffect({ leafletElement } = {}) {
    
    // console.log('business Detail mapEffect -- ', leafletElement);
    if ( !leafletElement ) return;

    
    const popup = L.popup({
      maxWidth: 800,
    });

    const location = LOCATION;

    // console.log('business Detail mapEffect location -- ', location);
    // console.log('business Detail mapEffect marker -- ', markerRef?.current);
    const { current = {} } = markerRef || {};
    const { leafletElement: marker } = current;


  }

  const showMap = () => {
    LOCATION.lat = business.address?.latitude;
    LOCATION.lng = business.address?.longitude;

    const CENTER = [LOCATION.lat, LOCATION.lng];

    const mapSettings = {
        center: CENTER,
        defaultBaseMap: MapServiceProviders.MAPBOX,
        zoom: DEFAULT_ZOOM,
        zoomControl: false,
        mapEffect
    };    

    // console.log('showingMap ---- rendering');
    return (
        <InfoMap {...mapSettings}>
            <InfoMarker business={business}/>
        </InfoMap>
    );
  }


    const tabStyles = useStyles();

    return (
        <LayoutContent>
            <Header fullWidth={true}/>
            <SEO
                lang="es" title="Cocina para ti" keywords={[`solidaridad`, `barrio`, `madrid`, `restaurantes`, `para llevar`, `banco de alimentos`]} 
            />         

            <SectionContainer id="business-detail">
                {isFetching && 
                    <CircularProgress color="primary" size="5rem" thickness={2}/>
                }   

                {business &&       
                <>          
                <BusinessCard>
                <BusinessIImageContainer>
                    <BusinessIImage
                    src={business.image?.url}
                    />
                    <BusinessIImageOverlay/>
                    <BusinessIImageTitle>{data.data.name}</BusinessIImageTitle>

                </BusinessIImageContainer>
                <BusinessIContent>

                    <BusinessAddressContainer onClick={() => setTab(1)}>
                        <BusinessAddressPin
                        src={ImagePin}/>
                        <BusinessAddressText>{business.address.completeAddress}</BusinessAddressText>
                    </BusinessAddressContainer>
                    <BusinessIDescription dangerouslySetInnerHTML={{
                        __html: data.data.description}}></BusinessIDescription>

                    <div className="d-flex flex-row">
                        {business.rrssLinks?.map(rrss => (
                        <BusinessRRSSLink rrssLink={rrss} key={`rsslink-${rrss.id}`}/>
                        ))}
                    </div>                        
                </BusinessIContent>
                </BusinessCard>

                <BusinessTabContainer>
                
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    aria-label="scrollable auto tabs example"
                    >
                    <Tab label="INFORMACIÓN" {...a11yProps(0)} className={`${tabStyles.tabLabel} d-block d-sm-none`}/>
                    <Tab label="DIRECCIÓN" {...a11yProps(1)} className={`${tabStyles.tabLabel} ${business.address?.latitude?'d-block':'d-none'}`}/>
                    <Tab label="MENÚS DEL DÍA" {...a11yProps(2)} className={tabStyles.tabLabel}/>
                    <Tab label="CARTA" {...a11yProps(3)} className={tabStyles.tabLabel}/>
                    <Tab label="VALORACIONES" {...a11yProps(4)} className={tabStyles.tabLabel}/>                   
                    <Tab label="PROMOCIONES" {...a11yProps(5)} className={tabStyles.tabLabel}/>      
                </Tabs>
                </BusinessTabContainer>
                <SwipeableViews
                    className="w-100"
                    axis={'x'}
                    index={tab}
                    onChangeIndex={handleTabChangeIndex}
                >
                    <TabPanel tab={tab} index={0}>
                        <BusinessAddressContainer onClick={() => setTab(1)}>
                            <BusinessAddressPin
                            src={ImagePin}/>
                            <BusinessAddressText>{business.address.completeAddress}</BusinessAddressText>
                        </BusinessAddressContainer>
                        <BusinessIDescription dangerouslySetInnerHTML={{
                            __html: data.data.description}}></BusinessIDescription>

                        <div className="d-flex flex-row">
                            {business.rrssLinks?.map(rrss => (
                            <BusinessRRSSLink rrssLink={rrss} key={`rsslink-${rrss.id}`}/>
                            ))}
                        </div>  
                    </TabPanel>

                    <TabPanel tab={tab} index={1} >
                    {business.address?.latitude &&
                    showMap()
                    }
                    </TabPanel>

                    <TabPanel tab={tab} index={2} >
                        <TabContentStart>
                            <BusinessDetailComponentMenus business={business}/>
                        </TabContentStart>
                    </TabPanel>
                    <TabPanel tab={tab} index={3} >
                        <TabContent>
                            <BusinessDetailComponentCatalogs business={business}/>
                        </TabContent>
                    </TabPanel>                    
                    <TabPanel tab={tab} index={4} >
                        <TabContent>
                            <BusinessDetailEmptyListComponent text="En estos momentos no hay valoraciones confirmadas sobre este establecimiento"/>
                        </TabContent>
                    </TabPanel> 
                    <TabPanel tab={tab} index={5} >
                        <TabContent>
                            {/*<BusinessDetailEmptyListComponent text="En estos momentos no hay promociones activas en este establecimiento"/>*/}
                            <BusinessDetailComponentPromos business={business}/>
                        </TabContent>
                    </TabPanel>                                                            
                </SwipeableViews>     
                </>           
                }
                
            </SectionContainer>    
            
            
            <MiniFooter />
            <CookieBanner/>
        </LayoutContent>
    );
}

export default BusinessDetail;