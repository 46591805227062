
import React from 'react';
import tw from 'twin.macro';
import ImageEmpty from '@assets/images/app/business/empty.svg';

const TabContainer=tw.div`w-full flex flex-col flex-grow items-center sm:justify-center`;
const ImageTabEmpty=tw.img`w-2/3 sm:w-1/3`;
const EmptyText = tw.span`text-primary-800 text-center font-sans sm:text-xl mx-2 sm:mx-auto mt-2`;

type BusinessDetailEmptyListProps = {
    text: string
}

const BusinessDetailEmptyListComponent =  ( {text}: BusinessDetailEmptyListProps) => {


    return (
        <>
            <ImageTabEmpty src={ImageEmpty}/>
            <EmptyText className="font-weight-bold">{text}</EmptyText>
        </>
  );
}



export default BusinessDetailEmptyListComponent;