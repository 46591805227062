import React, { FunctionComponent, useState } from 'react';
import tw from 'twin.macro';

import { BusinessDetailTabProps } from '../@types/BusinessDetailTabProps';
import { useGetBusinessPromos } from '@services/business.service';
import { CircularProgress } from '@material-ui/core';
import BusinessDetailComponentCatalogItem from './business-detail-catalog.item';
import BusinessDetailMenuOfDayComponent from './business-detail-menuofday';
import { IMenu } from '@models/menu.model';
import BusinessDetailEmptyListComponent from './business-detail-empty-list';
import { IAPIResponse, IPagination } from '@models/api-response.model';
import { QueryResult } from 'react-query';
import { IMenuOfDay } from '@models/menuofday.model';
import { IPromo } from '@models/promo.model';
import PromoItemCardComponent from '@components/promo/PromoItemCardComponent';

const PromosContainer= tw.div`sm:pt-0 md:pt-2 z-0 grid grid-cols-1 sm:grid-cols-2 gap-4 w-full justify-start`;
//const PromosContainer= tw.div`md:pt-20 sm:pt-0 md:pt-10 z-0 justify-center grid grid-cols-1 sm:grid-cols-2 gap-4`;

export const BusinessDetailComponentPromos = ( {business}: BusinessDetailTabProps) => {
  const { status, data: promos, error, isFetching }:  QueryResult<IAPIResponse<IPagination<IPromo>>, Error> = useGetBusinessPromos(business.slug);

  const [pageNumber, setPageNumber] = useState(0);


  const showList = () => {

    // console.log('showList menusofday page ', data);
      return (
        <>
        {promos?.data?.content?.length > 0 && 
          <PromosContainer>
          {promos?.data?.content.map((promo: IPromo, index: number) => (
              <PromoItemCardComponent promo={promo} key={promo.token}></PromoItemCardComponent>
          ))}
          </PromosContainer>
        }

        {promos?.data?.content?.length === 0 &&
        <BusinessDetailEmptyListComponent text="En estos momentos no hay promociones activas en este establecimiento"/>
        }
        </>
      );
  }
  // const catalogs: IMenu[] = data?.content;
  return (

    <>
    {promos === undefined && 
    <CircularProgress color="primary" />
    }

    {promos && 
        showList()
    }
    </>
  );
}



export default BusinessDetailComponentPromos;